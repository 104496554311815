.container {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 50vh;
}

.starbox {
  position: absolute;
  height: 500px;
  width: 500px;
  top: 500px;
  right: 5000px;
}

.star1 {
  height: 500px;
  width: 500px;
  background-image: url(../../assets/cloudsBirdsStars/star1.svg);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  z-index: 3;
}

.sunContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 3vw;
  right: -5vw;
  z-index: -1;
}

@media (max-width: 700px) {
  .sunContainer {
    top: 25px;
    right: -10px;
  }
}

.sun {
  background-image: url(../../assets/cloudsBirdsStars/sun.svg);
  width: 55vw;
  height: 55vh;
  position: absolute;
  right: -18vw;
  background-repeat: no-repeat;
}

@media (max-width: 1000px) {
  .sun {
    width: 50vw;
    height: 50vw;
    top: -1vw;
    right: -5vw;
  }
}

@media (max-width: 700px) {
  .sun {
    width: 70vw;
    height: 70vw;
    top: -5vw;
    right: -10vw;
  }
}

.sunset {
  -webkit-animation-name: goDown;
          animation-name: goDown;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: forwards;
          animation-timing-function: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.sunrise {
  -webkit-animation-name: comeUp;
          animation-name: comeUp;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: forwards;
          animation-timing-function: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes twinkle {
  1% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  5% {
    -webkit-transform: scale(1.2) rotate(45deg);
            transform: scale(1.2) rotate(45deg);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(90deg);
            transform: scale(0.9) rotate(90deg);
  }
  15% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  20% {
    -webkit-transform: scale(0.5) rotate(45deg);
            transform: scale(0.5) rotate(45deg);
  }
  25% {
    -webkit-transform: scale(1.1) rotate(45deg);
            transform: scale(1.1) rotate(45deg);
  }
  30% {
    -webkit-transform: scale(0.3) rotate(45deg);
            transform: scale(0.3) rotate(45deg);
  }
  35% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  40% {
    -webkit-transform: scale(0.3) rotate(45deg);
            transform: scale(0.3) rotate(45deg);
  }
  45% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  50% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  55% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  60% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  65% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  70% {
    -webkit-transform: scale(0.5) rotate(45deg);
            transform: scale(0.5) rotate(45deg);
  }
  75% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  80% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  85% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  90% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  95% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
}

@keyframes twinkle {
  1% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  5% {
    -webkit-transform: scale(1.2) rotate(45deg);
            transform: scale(1.2) rotate(45deg);
  }
  10% {
    -webkit-transform: scale(0.9) rotate(90deg);
            transform: scale(0.9) rotate(90deg);
  }
  15% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  20% {
    -webkit-transform: scale(0.5) rotate(45deg);
            transform: scale(0.5) rotate(45deg);
  }
  25% {
    -webkit-transform: scale(1.1) rotate(45deg);
            transform: scale(1.1) rotate(45deg);
  }
  30% {
    -webkit-transform: scale(0.3) rotate(45deg);
            transform: scale(0.3) rotate(45deg);
  }
  35% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  40% {
    -webkit-transform: scale(0.3) rotate(45deg);
            transform: scale(0.3) rotate(45deg);
  }
  45% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  50% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  55% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  60% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  65% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  70% {
    -webkit-transform: scale(0.5) rotate(45deg);
            transform: scale(0.5) rotate(45deg);
  }
  75% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  80% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  85% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  90% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
  95% {
    -webkit-transform: scale(1.5) rotate(45deg);
            transform: scale(1.5) rotate(45deg);
  }
}

@-webkit-keyframes goDown {
  0% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  12% {
    -webkit-transform: translateY(-3px) translateX(-3px);
            transform: translateY(-3px) translateX(-3px);
  }
  14% {
    -webkit-transform: translateY(-4px) translateX(3px);
            transform: translateY(-4px) translateX(3px);
  }
  16% {
    -webkit-transform: translateY(-6px) translateX(-3px);
            transform: translateY(-6px) translateX(-3px);
  }
  18% {
    -webkit-transform: translateY(-8px) translateX(3px);
            transform: translateY(-8px) translateX(3px);
  }
  20% {
    -webkit-transform: translateY(-6px) translateX(-3px);
            transform: translateY(-6px) translateX(-3px);
  }
  22% {
    -webkit-transform: translateY(-4px) translateX(3px);
            transform: translateY(-4px) translateX(3px);
  }
  24% {
    -webkit-transform: translateY(-3px) translateX(-3px);
            transform: translateY(-3px) translateX(-3px);
  }
  26% {
    -webkit-transform: translateY(-2px) translateX(3px);
            transform: translateY(-2px) translateX(3px);
  }
  100% {
    -webkit-transform: translateY(100%) translateX(120%);
            transform: translateY(100%) translateX(120%);
  }
}

@keyframes goDown {
  0% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  12% {
    -webkit-transform: translateY(-3px) translateX(-3px);
            transform: translateY(-3px) translateX(-3px);
  }
  14% {
    -webkit-transform: translateY(-4px) translateX(3px);
            transform: translateY(-4px) translateX(3px);
  }
  16% {
    -webkit-transform: translateY(-6px) translateX(-3px);
            transform: translateY(-6px) translateX(-3px);
  }
  18% {
    -webkit-transform: translateY(-8px) translateX(3px);
            transform: translateY(-8px) translateX(3px);
  }
  20% {
    -webkit-transform: translateY(-6px) translateX(-3px);
            transform: translateY(-6px) translateX(-3px);
  }
  22% {
    -webkit-transform: translateY(-4px) translateX(3px);
            transform: translateY(-4px) translateX(3px);
  }
  24% {
    -webkit-transform: translateY(-3px) translateX(-3px);
            transform: translateY(-3px) translateX(-3px);
  }
  26% {
    -webkit-transform: translateY(-2px) translateX(3px);
            transform: translateY(-2px) translateX(3px);
  }
  100% {
    -webkit-transform: translateY(100%) translateX(120%);
            transform: translateY(100%) translateX(120%);
  }
}

@-webkit-keyframes comeUp {
  from {
    -webkit-transform: translateY(100%) translateX(120%);
            transform: translateY(100%) translateX(120%);
  }
  20% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
  to {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
}

@keyframes comeUp {
  from {
    -webkit-transform: translateY(100%) translateX(120%);
            transform: translateY(100%) translateX(120%);
  }
  20% {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
  to {
    -webkit-transform: translateY(0px) translateX(0px);
            transform: translateY(0px) translateX(0px);
  }
}

.cloud-box-high {
  position: relative;
  top: 90px;
  left: 0;
  -webkit-animation-name: float;
          animation-name: float;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 80s;
          animation-duration: 80s;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

@media (max-width: 700px) {
  .cloud-box-high {
    top: 0;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
}

.cloud-box-mid {
  position: relative;
  top: 225px;
  -webkit-animation-name: float;
          animation-name: float;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 98s;
          animation-duration: 98s;
}

@media (max-width: 700px) {
  .cloud-box-mid {
    -webkit-animation-duration: 47ss;
            animation-duration: 47ss;
  }
}

.cloud-box-low {
  position: relative;
  top: 335px;
  -webkit-animation-name: float;
          animation-name: float;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 135s;
          animation-duration: 135s;
}

@media (max-width: 700px) {
  .cloud-box-low {
    -webkit-animation-duration: 60s;
            animation-duration: 60s;
  }
}

.cloud-one {
  background-image: url(../../assets/cloudsBirdsStars/cloud1.svg);
  width: 400px;
  height: 350px;
  position: relative;
  left: 0px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@media (max-width: 900px) {
  .cloud-one {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-two {
  background-image: url(../../assets/cloudsBirdsStars/cloud2.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -200px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@media (max-width: 900px) {
  .cloud-two {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-three {
  background-image: url(../../assets/cloudsBirdsStars/cloud3.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -1050px;
  -webkit-transform: scale(1.4) rotate(-1.5deg);
          transform: scale(1.4) rotate(-1.5deg);
}

@media (max-width: 900px) {
  .cloud-three {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-four {
  background-image: url(../../assets/cloudsBirdsStars/cloud4.svg);
  background-size: auto 100%;
  width: 500px;
  height: 500px;
  position: relative;
  top: -120px;
  left: -2450px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@media (max-width: 900px) {
  .cloud-four {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-five {
  background-image: url(../../assets/cloudsBirdsStars/cloud5.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -2050px;
  -webkit-transform: scale(1.2) translateX(-125px) translateY(-100px);
          transform: scale(1.2) translateX(-125px) translateY(-100px);
}

@media (max-width: 700px) {
  .cloud-five {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@media (max-width: 700px) and (max-width: 900px) {
  .cloud-five {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-six {
  background-image: url(../../assets/cloudsBirdsStars/cloud6.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -3050px;
}

@media (max-width: 900px) {
  .cloud-six {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

.cloud-seven {
  background-image: url(../../assets/cloudsBirdsStars/cloud7.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -1850px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@media (max-width: 900px) {
  .cloud-seven {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-eight {
  background-image: url(../../assets/cloudsBirdsStars/cloud8.svg);
  background-size: auto 100%;
  width: 400px;
  top: 69px;
  height: 350px;
  position: relative;
  left: -1800px;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@media (max-width: 900px) {
  .cloud-eight {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.cloud-nine {
  background-image: url(../../assets/cloudsBirdsStars/cloud2.svg);
  width: 400px;
  height: 350px;
  top: 99px;
  position: relative;
  left: -2900px;
}

@media (max-width: 900px) {
  .cloud-nine {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateX(-65vw);
            transform: translateX(-65vw);
  }
  100% {
    -webkit-transform: translateX(175vw);
            transform: translateX(175vw);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateX(-65vw);
            transform: translateX(-65vw);
  }
  100% {
    -webkit-transform: translateX(175vw);
            transform: translateX(175vw);
  }
}

.bird {
  background-image: url(../../assets/cloudsBirdsStars/Allbirds.svg);
  background-size: auto 100%;
  width: 150px;
  height: 175px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
          animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(30);
          animation-timing-function: steps(30);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird--one {
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.bird--two {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.bird--three {
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.bird--four {
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
}

.bird--five {
  -webkit-animation-duration: 1.7s;
          animation-duration: 1.7s;
}

.bird--six {
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
}

.bird--seven {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.bird-box {
  position: absolute;
  top: 25vh;
  left: -38%;
  -webkit-transform: scale(0.4) translateX(-10vw);
          transform: scale(0.4) translateX(-10vw);
  will-change: transform;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird-box--one {
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
  -webkit-animation-name: fly-right-one;
          animation-name: fly-right-one;
}

@media (max-width: 500px) {
  .bird-box--one {
    -webkit-animation-duration: 9s;
            animation-duration: 9s;
  }
}

.bird-box--two {
  -webkit-animation-name: fly-right-two;
          animation-name: fly-right-two;
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
}

@media (max-width: 500px) {
  .bird-box--two {
    -webkit-animation-duration: 8s;
            animation-duration: 8s;
  }
}

.bird-box-high {
  position: absolute;
  top: 15vh;
  will-change: transform;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird-box-high--one {
  -webkit-animation-duration: 23s;
          animation-duration: 23s;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-name: fly-right-one;
          animation-name: fly-right-one;
}

@media (max-width: 500px) {
  .bird-box-high--one {
    -webkit-animation-duration: 8s;
            animation-duration: 8s;
  }
}

.bird-box-high--two {
  -webkit-animation-duration: 23s;
          animation-duration: 23s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  -webkit-animation-name: fly-right-two;
          animation-name: fly-right-two;
}

@media (max-width: 500px) {
  .bird-box-high--two {
    -webkit-animation-duration: 9s;
            animation-duration: 9s;
  }
}

.bird-box-low {
  position: absolute;
  top: 54vh;
  left: -30vw;
  will-change: transform;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird-box-low--one {
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  -webkit-transform: scale(0) translateX(-10vw);
          transform: scale(0) translateX(-10vw);
  -webkit-animation-name: fly-right-one;
          animation-name: fly-right-one;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@media (max-width: 500px) {
  .bird-box-low--one {
    -webkit-animation-duration: 8s;
            animation-duration: 8s;
  }
}

.bird-box-low--two {
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
  -webkit-animation-delay: 6.1s;
          animation-delay: 6.1s;
  -webkit-animation-name: fly-right-two;
          animation-name: fly-right-two;
}

@media (max-width: 500px) {
  .bird-box-low--two {
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
  }
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -4500px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -4500px 0;
  }
}

@-webkit-keyframes fly-right-one {
  0% {
    -webkit-transform: scale(0.3) translateX(-85vw);
            transform: scale(0.3) translateX(-85vw);
  }
  10% {
    -webkit-transform: translateY(2vh) translateX(-62vw) scale(0.4);
            transform: translateY(2vh) translateX(-62vw) scale(0.4);
  }
  20% {
    -webkit-transform: translateY(0vh) translateX(-40vw) scale(0.5);
            transform: translateY(0vh) translateX(-40vw) scale(0.5);
  }
  30% {
    -webkit-transform: translateY(4vh) translateX(-17.5vw) scale(0.6);
            transform: translateY(4vh) translateX(-17.5vw) scale(0.6);
  }
  40% {
    -webkit-transform: translateY(2vh) translateX(5vw) scale(0.6);
            transform: translateY(2vh) translateX(5vw) scale(0.6);
  }
  50% {
    -webkit-transform: translateY(0vh) translateX(28vw) scale(0.6);
            transform: translateY(0vh) translateX(28vw) scale(0.6);
  }
  60% {
    -webkit-transform: translateY(0vh) translateX(50vw) scale(0.6);
            transform: translateY(0vh) translateX(50vw) scale(0.6);
  }
  70% {
    -webkit-transform: translateY(0vh) translateX(69vw) scale(0.6);
            transform: translateY(0vh) translateX(69vw) scale(0.6);
  }
  80% {
    -webkit-transform: translateY(0vh) translateX(87vw) scale(0.6);
            transform: translateY(0vh) translateX(87vw) scale(0.6);
  }
  90% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    -webkit-transform: translateY(0vh) translateX(150vw) scale(0.6);
            transform: translateY(0vh) translateX(150vw) scale(0.6);
  }
}

@keyframes fly-right-one {
  0% {
    -webkit-transform: scale(0.3) translateX(-85vw);
            transform: scale(0.3) translateX(-85vw);
  }
  10% {
    -webkit-transform: translateY(2vh) translateX(-62vw) scale(0.4);
            transform: translateY(2vh) translateX(-62vw) scale(0.4);
  }
  20% {
    -webkit-transform: translateY(0vh) translateX(-40vw) scale(0.5);
            transform: translateY(0vh) translateX(-40vw) scale(0.5);
  }
  30% {
    -webkit-transform: translateY(4vh) translateX(-17.5vw) scale(0.6);
            transform: translateY(4vh) translateX(-17.5vw) scale(0.6);
  }
  40% {
    -webkit-transform: translateY(2vh) translateX(5vw) scale(0.6);
            transform: translateY(2vh) translateX(5vw) scale(0.6);
  }
  50% {
    -webkit-transform: translateY(0vh) translateX(28vw) scale(0.6);
            transform: translateY(0vh) translateX(28vw) scale(0.6);
  }
  60% {
    -webkit-transform: translateY(0vh) translateX(50vw) scale(0.6);
            transform: translateY(0vh) translateX(50vw) scale(0.6);
  }
  70% {
    -webkit-transform: translateY(0vh) translateX(69vw) scale(0.6);
            transform: translateY(0vh) translateX(69vw) scale(0.6);
  }
  80% {
    -webkit-transform: translateY(0vh) translateX(87vw) scale(0.6);
            transform: translateY(0vh) translateX(87vw) scale(0.6);
  }
  90% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    -webkit-transform: translateY(0vh) translateX(150vw) scale(0.6);
            transform: translateY(0vh) translateX(150vw) scale(0.6);
  }
}

@-webkit-keyframes fly-right-two {
  0% {
    -webkit-transform: scale(0.4) translateX(-50vw);
            transform: scale(0.4) translateX(-50vw);
  }
  10% {
    -webkit-transform: translateY(2vh) translateX(-31vw) scale(0.4);
            transform: translateY(2vh) translateX(-31vw) scale(0.4);
  }
  20% {
    -webkit-transform: translateY(0vh) translateX(-12vw) scale(0.5);
            transform: translateY(0vh) translateX(-12vw) scale(0.5);
  }
  30% {
    -webkit-transform: translateY(4vh) translateX(12vw) scale(0.6);
            transform: translateY(4vh) translateX(12vw) scale(0.6);
  }
  40% {
    -webkit-transform: translateY(2vh) translateX(33vw) scale(0.6);
            transform: translateY(2vh) translateX(33vw) scale(0.6);
  }
  50% {
    -webkit-transform: translateY(0vh) translateX(54vw) scale(0.6);
            transform: translateY(0vh) translateX(54vw) scale(0.6);
  }
  60% {
    -webkit-transform: translateY(0vh) translateX(78vw) scale(0.6);
            transform: translateY(0vh) translateX(78vw) scale(0.6);
  }
  70% {
    -webkit-transform: translateY(0vh) translateX(98vw) scale(0.6);
            transform: translateY(0vh) translateX(98vw) scale(0.6);
  }
  80% {
    -webkit-transform: translateY(0vh) translateX(118vw) scale(0.6);
            transform: translateY(0vh) translateX(118vw) scale(0.6);
  }
  90% {
    -webkit-transform: translateY(0vh) translateX(130vw) scale(0.6);
            transform: translateY(0vh) translateX(130vw) scale(0.6);
  }
  100% {
    -webkit-transform: translateY(0vh) translateX(150vw) scale(0.6);
            transform: translateY(0vh) translateX(150vw) scale(0.6);
  }
}

@keyframes fly-right-two {
  0% {
    -webkit-transform: scale(0.4) translateX(-50vw);
            transform: scale(0.4) translateX(-50vw);
  }
  10% {
    -webkit-transform: translateY(2vh) translateX(-31vw) scale(0.4);
            transform: translateY(2vh) translateX(-31vw) scale(0.4);
  }
  20% {
    -webkit-transform: translateY(0vh) translateX(-12vw) scale(0.5);
            transform: translateY(0vh) translateX(-12vw) scale(0.5);
  }
  30% {
    -webkit-transform: translateY(4vh) translateX(12vw) scale(0.6);
            transform: translateY(4vh) translateX(12vw) scale(0.6);
  }
  40% {
    -webkit-transform: translateY(2vh) translateX(33vw) scale(0.6);
            transform: translateY(2vh) translateX(33vw) scale(0.6);
  }
  50% {
    -webkit-transform: translateY(0vh) translateX(54vw) scale(0.6);
            transform: translateY(0vh) translateX(54vw) scale(0.6);
  }
  60% {
    -webkit-transform: translateY(0vh) translateX(78vw) scale(0.6);
            transform: translateY(0vh) translateX(78vw) scale(0.6);
  }
  70% {
    -webkit-transform: translateY(0vh) translateX(98vw) scale(0.6);
            transform: translateY(0vh) translateX(98vw) scale(0.6);
  }
  80% {
    -webkit-transform: translateY(0vh) translateX(118vw) scale(0.6);
            transform: translateY(0vh) translateX(118vw) scale(0.6);
  }
  90% {
    -webkit-transform: translateY(0vh) translateX(130vw) scale(0.6);
            transform: translateY(0vh) translateX(130vw) scale(0.6);
  }
  100% {
    -webkit-transform: translateY(0vh) translateX(150vw) scale(0.6);
            transform: translateY(0vh) translateX(150vw) scale(0.6);
  }
}
