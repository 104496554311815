.container {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 50vh;
}

.starbox {
  position: absolute;
  height: 500px;
  width: 500px;
  top: 500px;
  right: 5000px;
}
.star1 {
  height: 500px;
  width: 500px;
  background-image: url(../../assets/cloudsBirdsStars/star1.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  z-index: 3;

  // animation-name: twinkle;
  // height: 50px;
  // width: max-content;
  // animation-timing-function: ease-in-out;
  // animation-duration: 10s;
}

.sunContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 3vw;
  right: -5vw;
  z-index: -1;
  // animation-name: float;
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;
  // animation-duration: 80s;
  // animation-delay: -0.8s;
  @media (max-width: 700px) {
    top: 25px;
    right: -10px;
  }
}

//background-color: ${(props) => props.theme.two};

.sun {
  background-image: url(../../assets/cloudsBirdsStars/sun.svg);
  width: 55vw;
  height: 55vh;
  position: absolute;
  right: -18vw;
  background-repeat: no-repeat;
  @media (max-width: 1000px) {
    width: 50vw;
    height: 50vw;
    top: -1vw;
    right: -5vw;
  }
  @media (max-width: 700px) {
    width: 70vw;
    height: 70vw;
    top: -5vw;
    right: -10vw;
  }
}
.sunset {
  // animation: goDown 1.5s ease-in-out 2s forwards;
  animation-name: goDown;
  animation-timing-function: ease-in-out;
  animation-timing-function: forwards;
  animation-duration: 1s;
}
.sunrise {
  animation-name: comeUp;
  animation-timing-function: ease-in-out;
  animation-timing-function: forwards;
  animation-duration: 1s;
}

@keyframes twinkle {
  1% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(1.2) rotate(45deg);
  }
  10% {
    transform: scale(0.9) rotate(90deg);
  }
  15% {
    transform: scale(1.5) rotate(45deg);
  }
  20% {
    transform: scale(0.5) rotate(45deg);
  }
  25% {
    transform: scale(1.1) rotate(45deg);
  }
  30% {
    transform: scale(0.3) rotate(45deg);
  }
  35% {
    transform: scale(1.5) rotate(45deg);
  }
  40% {
    transform: scale(0.3) rotate(45deg);
  }
  45% {
    transform: scale(1.5) rotate(45deg);
  }
  50% {
    transform: scale(1.5) rotate(45deg);
  }
  55% {
    transform: scale(1.5) rotate(45deg);
  }
  60% {
    transform: scale(1.5) rotate(45deg);
  }
  65% {
    transform: scale(1.5) rotate(45deg);
  }
  70% {
    transform: scale(0.5) rotate(45deg);
  }
  75% {
    transform: scale(1.5) rotate(45deg);
  }
  80% {
    transform: scale(1.5) rotate(45deg);
  }
  85% {
    transform: scale(1.5) rotate(45deg);
  }
  90% {
    transform: scale(1.5) rotate(45deg);
  }
  95% {
    transform: scale(1.5) rotate(45deg);
  }
}

@keyframes goDown {
  0% {
    transform: translateY(-2px);
  }
  12% {
    transform: translateY(-3px) translateX(-3px);
  }
  14% {
    transform: translateY(-4px) translateX(3px);
  }
  16% {
    transform: translateY(-6px) translateX(-3px);
  }
  18% {
    transform: translateY(-8px) translateX(3px);
  }
  20% {
    transform: translateY(-6px) translateX(-3px);
  }
  22% {
    transform: translateY(-4px) translateX(3px);
  }
  24% {
    transform: translateY(-3px) translateX(-3px);
  }
  26% {
    transform: translateY(-2px) translateX(3px);
  }
  100% {
    transform: translateY(100%) translateX(120%);
  }
}
@keyframes comeUp {
  from {
    transform: translateY(100%) translateX(120%);
  }
  20% {
    transform: translateY(0px) translateX(0px);
  }
  to {
    transform: translateY(0px) translateX(0px);
  }
}

.cloud-box-high {
  position: relative;
  top: 90px;
  left: 0;
  animation-name: float;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 80s;
  animation-delay: -0.8s;
  @media (max-width: 700px) {
    top: 0;
    animation-duration: 40s;
  }
}
.cloud-box-mid {
  position: relative;
  top: 225px;
  animation-name: float;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 98s;
  @media (max-width: 700px) {
    animation-duration: 47ss;
  }
}
.cloud-box-low {
  position: relative;
  top: 335px;
  animation-name: float;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 135s;
  @media (max-width: 700px) {
    animation-duration: 60s;
  }
}
.cloud-one {
  background-image: url(../../assets/cloudsBirdsStars/cloud1.svg);
  width: 400px;
  height: 350px;
  position: relative;
  left: 0px;
  transform: scale(1.4);
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}
.cloud-two {
  background-image: url(../../assets/cloudsBirdsStars/cloud2.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -200px;
  transform: scale(1.4);
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}
.cloud-three {
  background-image: url(../../assets/cloudsBirdsStars/cloud3.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -1050px;
  // left: 300vw;
  transform: scale(1.4) rotate(-1.5deg);
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}
.cloud-four {
  background-image: url(../../assets/cloudsBirdsStars/cloud4.svg);
  background-size: auto 100%;
  width: 500px;
  height: 500px;
  position: relative;
  top: -120px;
  left: -2450px;
  transform: scale(1.4);
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}
.cloud-five {
  background-image: url(../../assets/cloudsBirdsStars/cloud5.svg);
  // background-color: aliceblue;
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -2050px;
  transform: scale(1.2) translateX(-125px) translateY(-100px);
  @media (max-width: 700px) {
    transform: scale(0.8);
    @media (max-width: 900px) {
      transform: scale(0.8);
    }
  }
}
.cloud-six {
  background-image: url(../../assets/cloudsBirdsStars/cloud6.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -3050px;
  // transform: scale(1.4);
  @media (max-width: 900px) {
    transform: scale(0.9);
  }
}
.cloud-seven {
  background-image: url(../../assets/cloudsBirdsStars/cloud7.svg);
  background-size: auto 100%;
  width: 400px;
  height: 350px;
  position: relative;
  left: -1850px;
  transform: scale(1.4);
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}
.cloud-eight {
  background-image: url(../../assets/cloudsBirdsStars/cloud8.svg);
  background-size: auto 100%;
  width: 400px;
  top: 69px;
  height: 350px;
  position: relative;
  left: -1800px;
  transform: scale(1.4);
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}

.cloud-nine {
  background-image: url(../../assets/cloudsBirdsStars/cloud2.svg);
  width: 400px;
  height: 350px;
  top: 99px;
  position: relative;
  left: -2900px;
  @media (max-width: 900px) {
    transform: scale(0.8);
  }
}

@keyframes float {
  0% {
    transform: translateX(-65vw);
  }

  100% {
    transform: translateX(175vw);
  }
}

.bird {
  background-image: url(../../assets/cloudsBirdsStars/Allbirds.svg);
  background-size: auto 100%;
  width: 150px;
  height: 175px;
  will-change: background-position;
  animation-name: fly-cycle;
  animation-timing-function: steps(30);
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 1.2s;
    animation-delay: -0.8s;
  }

  &--two {
    animation-duration: 0.8s;
    animation-delay: -0.9s;
  }

  &--three {
    animation-duration: 0.9s;
    animation-delay: -0.5s;
  }

  &--four {
    animation-duration: 1.6s;
  }
  &--five {
    animation-duration: 1.7s;
  }
  &--six {
    animation-duration: 1.2s;
    animation-delay: 1.9s;
  }
  &--seven {
    animation-duration: 0.8s;
    animation-delay: 1s;
  }
}

.bird-box {
  position: absolute;
  top: 25vh;
  left: -38%;
  transform: scale(0.4) translateX(-10vw);
  will-change: transform;
  // animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 17s;
    animation-delay: 6s;
    animation-name: fly-right-one;

    @media (max-width: 500px) {
      animation-duration: 9s;
    }
  }
  &--two {
    animation-name: fly-right-two;
    animation-duration: 17s;
    animation-delay: 6s;
    @media (max-width: 500px) {
      animation-duration: 8s;
    }
  }
}

.bird-box-high {
  position: absolute;
  top: 15vh;
  will-change: transform;
  // animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 23s;
    animation-delay: -5s;
    animation-name: fly-right-one;

    @media (max-width: 500px) {
      animation-duration: 8s;
    }
  }
  &--two {
    animation-duration: 23s;
    animation-delay: -0.5s;
    animation-name: fly-right-two;

    @media (max-width: 500px) {
      animation-duration: 9s;
    }
  }
}

.bird-box-low {
  position: absolute;
  top: 54vh;
  left: -30vw;
  will-change: transform;
  // animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 20s;
    transform: scale(0) translateX(-10vw);
    animation-name: fly-right-one;
    animation-delay: 0.5s;
    @media (max-width: 500px) {
      animation-duration: 8s;
    }
  }
  &--two {
    animation-duration: 17s;
    animation-delay: 6.1s;
    animation-name: fly-right-two;
    @media (max-width: 500px) {
      animation-duration: 7s;
    }
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -4500px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-85vw);
  }

  10% {
    transform: translateY(2vh) translateX(-62vw) scale(0.4);
  }

  20% {
    transform: translateY(0vh) translateX(-40vw) scale(0.5);
  }

  30% {
    transform: translateY(4vh) translateX(-17.5vw) scale(0.6);
  }

  40% {
    transform: translateY(2vh) translateX(5vw) scale(0.6);
  }

  50% {
    transform: translateY(0vh) translateX(28vw) scale(0.6);
  }

  60% {
    transform: translateY(0vh) translateX(50vw) scale(0.6);
  }
  70% {
    transform: translateY(0vh) translateX(69vw) scale(0.6);
  }
  80% {
    transform: translateY(0vh) translateX(87vw) scale(0.6);
  }
  90% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    transform: translateY(0vh) translateX(150vw) scale(0.6);
  }
}
@keyframes fly-right-two {
  0% {
    transform: scale(0.4) translateX(-50vw);
  }

  10% {
    transform: translateY(2vh) translateX(-31vw) scale(0.4);
  }

  20% {
    transform: translateY(0vh) translateX(-12vw) scale(0.5);
  }

  30% {
    transform: translateY(4vh) translateX(12vw) scale(0.6);
  }

  40% {
    transform: translateY(2vh) translateX(33vw) scale(0.6);
  }

  50% {
    transform: translateY(0vh) translateX(54vw) scale(0.6);
  }

  60% {
    transform: translateY(0vh) translateX(78vw) scale(0.6);
  }
  70% {
    transform: translateY(0vh) translateX(98vw) scale(0.6);
  }
  80% {
    transform: translateY(0vh) translateX(118vw) scale(0.6);
  }
  90% {
    transform: translateY(0vh) translateX(130vw) scale(0.6);
  }

  100% {
    transform: translateY(0vh) translateX(150vw) scale(0.6);
  }
}
